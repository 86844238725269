import React from 'react';
import ReactDOM from 'react-dom';
import App, {AppWrap} from 'app/main/App';
import reportWebVitals from './reportWebVitals';
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import createMuiTheme, {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {ThemeColorsService} from "shared-services/theme-colors-service";
import AppThemeService, {DEFAULT_FONT} from "app/services/theme/appTheme.service";
import {IWidgetPalette, IWidgetTheme, themeTypes} from "shared-types/index";

export const APP_COLOR = 'hex,717171';

// Hot Module Replacement
declare let module: { hot: any };


function render(AppVal: any) {

    const theme: IWidgetTheme = {
        palette: AppThemeService.getThemeDefaultPalette(),
        typography: AppThemeService.getThemeDefaultTypography(),
        defaultColors: true,
        defaultFont: DEFAULT_FONT,
        type: themeTypes.light
    }

    // @todo: don't think we need to get colors from query string for group widget
    const {palette, defaultColors} = ThemeColorsService.getColorsFromString(theme, APP_COLOR, theme.palette);
    theme.palette = palette as IWidgetPalette;
    theme.defaultColors = defaultColors;

    let muiTheme = createMuiTheme(theme);
    muiTheme = responsiveFontSizes(muiTheme);

    ReactDOM.render(
        // <React.StrictMode>
        <AppWrap theme={theme}>
            <AppVal/>
        </AppWrap>,
        // </React.StrictMode>,
        document.getElementById('root')
    );
}

render(App);

if (module.hot) {
    module.hot.accept("app/main/App", () => {
        const App = require("app/main/App").default;
        render(App);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
